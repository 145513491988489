<template>
  <v-text-field
    :key="nbChanges"
    v-model.number="innerValue"
    :label="label"
    :filled="!editable"
    :readonly="!editable"
    :clearable="editable"
    outlined
    dense
    hide-details
    :class="getClasses"
    ref="myTextField"
    type="number"
  />
</template>

<script>
import baseInput from "./WBaseInputMixin"; // this mixin helps handling some common mechanics
import { VTextField } from "vuetify/lib";

export default {
  name: "w-num-field",

  mixins: [baseInput],

  components: { VTextField },

  props: {
    num: { type: Number, default: undefined },
  },

  data: () => ({
    // menuOpen: false,
  }),

  computed: {
    getClasses() {
      if (this.num) {
        // thanks to the :key prop, when a value is (asynchronously) provided, the component is re-rendered,
        // and so some computations made without the value are done again
        return "w-num-field mb-5";
      }
      return "w-num-field w-num-field-empty mb-5";
    },
    innerValue: {
      get: function () {
        return this.num;
      },
      set: function (val) {
        if (val === "") val = null;

        // first, let's warn our parent that the value has changed
        this.$emit("update:num", val);

        // // then, we need to make sure the component is correctly displayed
        // this.refreshComponent(this.innerValue);
      },
    },
  },

  watch: {
    innerValue(newVal, oldVal) {
      this.refreshComponent(newVal, oldVal);
    },
  },

  methods: {},
};
</script>

<style scoped>
.w-num-field /deep/ .v-input__slot {
  height: 50px;
}
.w-num-field /deep/ .v-label {
  font-size: 20px;
}
.w-num-field-empty /deep/ .v-label {
  height: 25px;
  font-size: 16px;
  padding-top: 4px;
  padding-right: 2px;
  font-style: italic;
}
.w-num-field /deep/ .v-input__icon {
  margin-top: 4px;
}
</style>
