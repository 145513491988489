<template>
  <v-card>

    <!-- the header -->
    <v-card-title>

      <!-- the table title -->
      <span style="padding-left: 12px">{{ getTitle }}</span>

      <!-- the table action buttons -->
      <div
        v-for="item in actions"
        :key="item.action"
        style="padding-left: 12px"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-1"
              fab
              dark
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="$emit(item.action)"
            >
              <v-icon dark>{{ item.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.text }}</span>
        </v-tooltip>
      </div>

      <!-- the table filtering field -->
      <v-spacer></v-spacer>
      <v-text-field
        v-model="filter"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <!-- the data table, with grouping ------------------------ -->
    <v-data-table
      v-if="groupBy !== ''"
      :headers="headers"
      :items="items"
      :search="filter"
      :sort-by.sync="localSortBy"
      :sort-desc.sync="localSortDescBool"
      :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, -1] }"
      :items-per-page.sync="itemsPerPageInt"
      :page.sync="currentPage"
      :group-by="groupBy"
      :show-group-by="groupBy !== ''"
    >
      <!-- action -->
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="openEntity(item)"
        >mdi-eye</v-icon>
      </template>

      <!-- progress -->
      <template
        v-if="progressCol !== ''"
        v-slot:[`item.${progressCol}`]="{ item }"
      >
        <v-progress-linear
          background-color="grey lighten-1"
          color="green"
          :buffer-value="progressMax"
          :rounded="true"
          :value="item[progressCol]"
          height="30"
          dark
        >
          <div>{{ item[progressCol] }}</div>
        </v-progress-linear>
      </template>

      <!-- no data -->
      <template v-slot:no-data>
        <v-alert :value="true">Nothing to display here</v-alert>
      </template>
    </v-data-table>

    <!-- the data table, without grouping --------------------- -->
    <v-data-table
      v-else
      :headers="headers"
      :items="items"
      :search="filter"
      :sort-by.sync="localSortBy"
      :sort-desc.sync="localSortDescBool"
      :footer-props="{ 'items-per-page-options': [5, 10, 25, 50, -1] }"
      :items-per-page.sync="itemsPerPageInt"
      :page.sync="currentPage"
    >
      <!-- action -->
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="openEntity(item)"
        >mdi-eye</v-icon>
      </template>

      <!-- progress -->
      <template
        v-if="progressCol !== ''"
        v-slot:[`item.${progressCol}`]="{ item }"
      >
        <v-progress-linear
          background-color="grey lighten-1"
          color="green"
          :buffer-value="progressMax"
          :rounded="true"
          :value="item[progressCol]"
          height="30"
          dark
        >
          <div>{{ item[progressCol] }}</div>
        </v-progress-linear>
      </template>

      <!-- no data -->
      <template v-slot:no-data>
        <v-alert :value="true">Nothing to display here</v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import lsync from "../../shared/utils/lsync";
import {
  VAlert,
  VBtn,
  VCard,
  VCardTitle,
  VDataTable,
  VIcon,
  VProgressLinear,
  VSpacer,
  VTextField,
  VTooltip,
} from "vuetify/lib";

export default {
  name: "w-entity-list",

  components: {
    VAlert,
    VBtn,
    VCard,
    VCardTitle,
    VDataTable,
    VIcon,
    VProgressLinear,
    VSpacer,
    VTextField,
    VTooltip,
  },

  props: {
    actions: { type: Array, default: () => [] },
    columns: { type: Array, default: () => [] },
    groupBy: { type: String, default: "" },
    items: { type: Array, default: () => [] },
    readPath: { type: String, default: "" },
    readRef: { type: String, default: "" },
    rows: { type: String, default: "10" },
    progressCol: { type: String, default: "" },
    progressMax: { type: Number, default: 100 },
    sortBy: { type: String, default: "" },
    title: { type: String, default: "[TITLE]" },
  },

  data: () => ({
    currentPage: 1,
    filter: "",
    itemsPerPageString: "50",
    itemsPerPageInt: 50,
    localSortBy: "",
    localSortDescString: "asc",
    localSortDescBool: false,
  }),

  computed: {
    getTitle() {
      return this.title + " (" + this.items.length + ")";
    },
    headers() {
      if (this.showAction) {
        return [
          ...this.columns,
          {
            value: "action",
            text: "action",
            sortable: false,
            groupable: false,
          },
        ];
      }
      return this.columns;
    },
    showAction() {
      return this.readRef !== "";
    },
  },

  watch: {
    currentPage(newValue) {
      this.currentPage = lsync(this.currentPageUid, newValue);
    },

    filter(newValue) {
      if (newValue === null) {
        newValue = "";
      }
      this.filter = lsync(this.filterUid, newValue);
    },

    items() {
      this.currentPage = parseInt(lsync(this.currentPageUid)) || 1;
    },

    itemsPerPageInt(newValue) {
      this.itemsPerPageInt = newValue;
      this.itemsPerPageString = lsync(
        this.itemsPerPageUid,
        this.itemsPerPageInt === -1 ? "All" : this.itemsPerPageInt.toString()
      );
    },

    localSortBy(newValue) {
      this.localSortBy = lsync(this.sortByUid, newValue);
    },

    localSortDescBool(newValue) {
      this.localSortDescBool = newValue;
      this.localSortDescString = lsync(
        this.sortDescUid,
        this.localSortDescBool === true ? "desc" : "asc"
      );
    },
  },

  created() {
    // parsing the prop on the sorting
    if (this.sortBy.indexOf(",") > 0) {
      let elems = this.sortBy.split(",");
      this.sortByCol = elems[0];
      this.sortByDesc = elems[1] === "desc" ? "desc" : "asc";
    } else {
      this.sortByCol = this.sortBy;
      this.sortByDesc = "asc";
    }

    // building unique IDs (= keys for the local storage)
    this.tableUid = this.$route.path + "-" + this.title;
    this.currentPageUid = this.tableUid + "-currentPage";
    this.filterUid = this.tableUid + "-filter";
    this.itemsPerPageUid = this.tableUid + "-itemsPerPage";
    this.sortByUid = this.tableUid + "-sortBy";
    this.sortDescUid = this.tableUid + "-sortDesc";

    // initialising the fields
    this.filter = lsync(this.filterUid);
    this.itemsPerPageString = lsync(this.itemsPerPageUid) || this.rows; // if there's nothing in the local storage, let's take the value coming from the prop
    this.itemsPerPageInt =
      this.itemsPerPageString === "All"
        ? -1
        : parseInt(this.itemsPerPageString); // the prop in v-data-table needs an integer, though
    this.localSortBy = lsync(this.sortByUid) || this.sortByCol; // if there's nothing in the local storage, let's take the value coming from the prop
    this.localSortDescString = lsync(this.sortDescUid) || this.sortByDesc; // if there's nothing in the local storage, let's take the value coming from the prop
    this.localSortDescBool = this.localSortDescString === "desc"; // the prop in v-data-table needs a boolean, though
  },

  methods: {
    openEntity(selected) {
      const entity = this.items.find((ent) => {
        return ent.id === selected.id;
      });
      this.$router.push({
        name: this.readPath,
        params: { which: entity[this.readRef] },
      });
    },
  },
};
</script>

<style scoped>
.v-progress-linear__content {
  z-index: 1;
  width: 200px;
}
</style>
