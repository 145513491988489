<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! all flow fields                                                                                              ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! flow identification                                                                                        ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col>
            <v-card outlined>
              <v-card-title>task configuration</v-card-title>
              <v-col>
                <v-row>
                  <v-col cols="3" class="py-0 my-0">
                    <w-text-field
                      :text="task.flow.name"
                      label="parent flow"
                      :go-to="readFlowPath"
                      :go-to-ref="task.flow.reference"
                    />
                    <w-text-field
                      :text="task.group.name"
                      label="member of group"
                      :go-to="readGroupPath"
                      :go-to-ref="task.group.globalReference"
                    />
                  </v-col>
                  <v-col cols="3" class="py-0 my-0">
                    <w-text-field
                      :text="task.reference"
                      label="task reference"
                    />
                    <w-text-field :text="task.name" label="task name" />
                  </v-col>
                  <v-col cols="6" class="py-0 my-0">
                    <w-textarea
                      :text="task.description"
                      label="task description"
                    />
                    <v-row>
                      <v-col cols="4" class="pt-2 my-0">
                        <w-text-field
                          :text="task.functionName"
                          label="running function"
                        />
                      </v-col>
                      <v-col cols="2" class="py-0 my-0">
                        <w-checkbox :value="task.manual" label="is manual" />
                      </v-col>
                      <v-col cols="3" class="pt-2 my-0">
                        <w-text-field
                          :text="task.intervals"
                          label="intervals"
                        />
                      </v-col>
                      <v-col cols="3" class="pt-2 my-0">
                        <w-num-field
                          :num="task.nbParallelIntervals"
                          label="nb parallel intervals"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row> </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>

        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! Items                                                                                                        ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <v-col>
            <w-tabs>
              <v-tab>task chain</v-tab>
              <v-tab-item>
                <v-row>
                  <v-col>
                    <v-card class="elevation-6">
                      <w-entity-list
                        :columns="taskColumns"
                        :items="task.dependsOn"
                        :read-path="readTaskPath"
                        read-ref="globalReference"
                        title="depends on"
                      />
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-card class="elevation-6">
                      <w-entity-list
                        :columns="taskColumns"
                        :items="task.nextTasks"
                        :read-path="readTaskPath"
                        read-ref="globalReference"
                        title="next tasks"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab>parameters</v-tab>
              <v-tab-item>
                <v-row>
                  <v-col>
                    <v-card class="elevation-6">
                      <w-entity-list
                        :columns="paramColumns"
                        :items="task.params"
                        title="task parameters"
                      />
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab>running tasks</v-tab>
              <v-tab-item>
                <w-entity-list
                  :columns="runningTaskColumns"
                  :items="task.runningTasks"
                  :read-path="readRunningTaskPath"
                  read-ref="id"
                  title="running tasks"
                  progress-col="currentProgress"
                />
              </v-tab-item>
            </w-tabs>
          </v-col>
        </v-row>
        <!-- --------------------------------------------------------------------------------------------------------- -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  VCard,
  VCardTitle,
  VCol,
  VContainer,
  VRow,
  VTab,
  VTabItem,
} from "vuetify/lib";

import api from "../../components/external/modules/shared/utils/api";
import WCheckbox from "../../components/external/modules/shared/views/WCheckbox.vue";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import WNumField from "../../components/external/modules/shared/views/WNumField.vue";
import WTabs from "../../components/external/modules/shared/views/WTabs.vue";
import WTextarea from "../../components/external/modules/shared/views/WTextarea.vue";
import WTextField from "../../components/external/modules/shared/views/WTextField.vue";
import path from "../../shared/paths";
import datehourformat from "../../components/external/modules/shared/utils/datehourformat";

export default {
  components: {
    VCard,
    VCardTitle,
    VCol,
    VContainer,
    VRow,
    VTab,
    VTabItem,
    WEntityList,
    WNumField,
    WTabs,
    WTextarea,
    WTextField,
    WCheckbox,
  },

  data: () => ({
    task: {
      flow: {},
      group: {},
      dependsOn: [],
      nextTasks: [],
      params: [],
      runningTasks: [],
    },

    taskColumns: [
      { value: "name", text: "name" },
      { value: "reference", text: "reference" },
      { value: "manual", text: "manual" },
      { value: "intervals", text: "intervals" },
      { value: "groupName", text: "group name", groupable: true },
    ],

    runningTaskColumns: [
      { value: "completeName", text: "name", groupable: false },
      { value: "start", text: "started", groupable: false },
      { value: "statusString", text: "status", groupable: false },
      { value: "currentProgress", text: "done %", groupable: false },
      { value: "finish", text: "finished", groupable: false },
      { value: "lastComment", text: "last comment", groupable: false },
    ],

    paramColumns: [
      { value: "paramID", text: "param ID" },
      { value: "value", text: "value" },
    ],

    readTaskPath: path.TASKCONFIG,
    readFlowPath: path.TASKFLOWCONFIG,
    readGroupPath: path.TASKGROUPCONFIG,
    readParamPath: path.TASKCONFIGPARAM,
    readRunningTaskPath: path.TASK,
  }),

  created() {
    this.loadFlow();
  },

  methods: {
    loadFlow() {
      api
        .get("taskconfig/read/" + this.$route.params.which)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            // setting the main object
            this.task = res.data.entity;

            // tweaking each task
            if (this.task.dependsOn) {
              this.task.dependsOn.forEach((task) => {
                task.groupName = task.group.name;
              });
            } else {
              this.task.dependsOn = [];
            }

            if (this.task.nextTasks) {
              this.task.nextTasks.forEach((task) => {
                task.groupName = task.group.name;
              });
            } else {
              this.task.nextTasks = [];
            }

            if (!this.task.params) {
              this.task.params = [];
            }

            if (this.task.runningTasks) {
              this.task.runningTasks.forEach((runningTask) => {
                runningTask.completeName =
                  "[" + runningTask.num + "] " + runningTask.name;
                if (runningTask.started) {
                  runningTask.start = datehourformat(runningTask.started, true);
                }
                if (runningTask.finished) {
                  runningTask.finish =
                    datehourformat(runningTask.finished, true) +
                    " in " +
                    runningTask.totalElapsed;
                } else {
                  if (runningTask.etaString) {
                    runningTask.finish =
                      "in " + runningTask.etaString + " (eta)";
                  }
                }
              });
            }
          } else {
            console.log(new Error("Failed searching the task flow config"));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.active-tab {
  background-color: #eef;
}
</style>