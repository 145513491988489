export default {
	props: {
		editable: { type: Boolean, default: false },
		label: { type: String, default: "" },
		text: { type: String, default: "" },
	},

	data: () => ({
		nbChanges: 0,
		timeout: null,
	}),

	computed: {
		innerValue: {
			get: function () {
				return this.text;
			},
			set: function (val) {
				this.$emit("update:text", val);
			},
		},
	},

	methods: {
		// we need the re-render the component since we've tweaked the label (increased its size),
		// before some calculations are done, leaving it incorrectly displayed
		refreshComponent(newVal, oldVal) {
			// console.log(
			// 	"Refreshing '" + this.label + "': " + oldVal + " -> " + newVal
			// );

			// the page has just been created
			if (!this.editable && this.nbChanges === 0) {
				// we need to re-render the field right away
				// console.log("re-render following page creation");
				this.nbChanges = this.nbChanges + 1;
			} else {
				// we're dealing with a user input

				// the user has wiped the field, so we can re-render now, then make sure (s)he still has the focus on
				if (!newVal) {
					this.nbChanges = this.nbChanges + 1;
					this.$nextTick(() => {
						// console.log("focus following immediate change");
						this.$refs.myTextField.$refs.input.focus();
					});
				} else if (!oldVal) {

					// the user is filling the input field... we need to wait a little time to start re-rendering, using a timeout
					// first, we may need to restart the current timeout
					if (this.timeout) clearTimeout(this.timeout);

					// then, changing the 'nbChanges' property which we use as a ':key', to force a re-render
					// and setting the focus back on, but with a little delay, to avoid frequent re-renders
					this.timeout = setTimeout(() => {
						this.nbChanges = this.nbChanges + 1;
						this.$nextTick(() => {
							// console.log("focus following deferred change");
							this.$refs.myTextField.$refs.input.focus();
						});
					}, 500);
				}
			}
		},
	},
};
