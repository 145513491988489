var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticStyle:{"padding-left":"12px"}},[_vm._v(_vm._s(_vm.getTitle))]),_vm._l((_vm.actions),function(item){return _c('div',{key:item.action,staticStyle:{"padding-left":"12px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$emit(item.action)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])],1)}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],2),(_vm.groupBy !== '')?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.filter,"sort-by":_vm.localSortBy,"sort-desc":_vm.localSortDescBool,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, -1] },"items-per-page":_vm.itemsPerPageInt,"page":_vm.currentPage,"group-by":_vm.groupBy,"show-group-by":_vm.groupBy !== ''},on:{"update:sortBy":function($event){_vm.localSortBy=$event},"update:sort-by":function($event){_vm.localSortBy=$event},"update:sortDesc":function($event){_vm.localSortDescBool=$event},"update:sort-desc":function($event){_vm.localSortDescBool=$event},"update:itemsPerPage":function($event){_vm.itemsPerPageInt=$event},"update:items-per-page":function($event){_vm.itemsPerPageInt=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEntity(item)}}},[_vm._v("mdi-eye")])]}},(_vm.progressCol !== '')?{key:("item." + _vm.progressCol),fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"background-color":"grey lighten-1","color":"green","buffer-value":_vm.progressMax,"rounded":true,"value":item[_vm.progressCol],"height":"30","dark":""}},[_c('div',[_vm._v(_vm._s(item[_vm.progressCol]))])])]}}:null,{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true}},[_vm._v("Nothing to display here")])]},proxy:true}],null,true)}):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.filter,"sort-by":_vm.localSortBy,"sort-desc":_vm.localSortDescBool,"footer-props":{ 'items-per-page-options': [5, 10, 25, 50, -1] },"items-per-page":_vm.itemsPerPageInt,"page":_vm.currentPage},on:{"update:sortBy":function($event){_vm.localSortBy=$event},"update:sort-by":function($event){_vm.localSortBy=$event},"update:sortDesc":function($event){_vm.localSortDescBool=$event},"update:sort-desc":function($event){_vm.localSortDescBool=$event},"update:itemsPerPage":function($event){_vm.itemsPerPageInt=$event},"update:items-per-page":function($event){_vm.itemsPerPageInt=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEntity(item)}}},[_vm._v("mdi-eye")])]}},(_vm.progressCol !== '')?{key:("item." + _vm.progressCol),fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"background-color":"grey lighten-1","color":"green","buffer-value":_vm.progressMax,"rounded":true,"value":item[_vm.progressCol],"height":"30","dark":""}},[_c('div',[_vm._v(_vm._s(item[_vm.progressCol]))])])]}}:null,{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true}},[_vm._v("Nothing to display here")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }