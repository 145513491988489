<template>
  <v-tabs grow active-class="active-tab" v-model="currentTabInt">
    <!-- the following allows to use w-tabs like v-tabs, i.e. setting v-tab and v-tab-item instances underneath -->
    <slot></slot>
  </v-tabs>
</template>

<script>
import lsync from "../../shared/utils/lsync";
import { VTabs } from "vuetify/lib";

export default {
  name: "w-tabs",

  components: {
    VTabs,
  },

  data: () => ({
    currentTabInt: 0,
  }),

  watch: {
    currentTabInt(newValue) {
      this.currentTabInt = newValue;
      this.currentTabString = lsync(
        this.currentTabUid,
        this.currentTabInt.toString()
      );
    },
  },

  created() {
    this.currentTabUid = this.$route.path + "-tabs";
    // this.currentTabString = lsync(this.currentTabUid);
    // this.currentTabInt =
    //   this.currentTabString === "" ? 0 : parseInt(this.currentTabString);
    this.currentTabString = lsync(this.currentTabUid) || "0";
    this.currentTabInt = parseInt(this.currentTabString);
  },
};
</script>

<style scoped>
.active-tab {
  background-color: #eef;
}
</style>