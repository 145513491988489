<template>
  <v-text-field
    :key="nbChanges"
    v-model="innerValue"
    :label="label"
    :filled="!editable"
    :readonly="!editable"
    :clearable="editable"
    outlined
    dense
    hide-details
    :class="getClasses"
    @click="handleClick"
    @click:append="handleClick"
    :append-icon="appendIcon"
    ref="myTextField"
  />
</template>

<script>
import baseInput from "./WBaseInputMixin"; // this mixin helps handling some common mechanics
import { VTextField } from "vuetify/lib";

export default {
  name: "w-text-field",

  mixins: [baseInput],

  components: { VTextField },

  props: {
    goTo: { type: String, default: undefined },
    goToRef: { type: String, default: undefined },
  },

  computed: {
    appendIcon() {
      if (this.goTo) {
        return "mdi-step-backward";
      }
      return "";
    },
    getClasses() {
      if (this.text) {
        return "w-text-field mb-5";
      }
      return "w-text-field w-text-field-empty mb-5";
    },
  },

  methods: {
    handleClick() {
      // this is a link to another entity...
      if (this.goTo) {
        if (this.goToRef) {
          this.$router.push({
            name: this.goTo,
            params: { which: this.goToRef },
          });
        } else {
          this.$router.push({ name: this.goTo });
        }
      }
    },
  },

  watch: {
    innerValue(newVal, oldVal) {
      this.refreshComponent(newVal, oldVal);
    },
  },
};
</script>

<style scoped>
.w-text-field /deep/ .v-input__slot {
  height: 50px;
}
.w-text-field /deep/ .v-label {
  font-size: 20px;
}
.w-text-field-empty /deep/ .v-label {
  height: 25px;
  font-size: 16px;
  padding-top: 4px;
  padding-right: 2px;
  font-style: italic;
}
.w-text-field /deep/ .v-input__icon {
  margin-top: 4px;
}
</style>